.hero {
	background-size: 100%, cover !important;
	background-position: center, center !important;
	width: 100%;
	position: relative;
	background-size: cover;
}

@keyframes animateHeroimage {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.hero-content {
	max-width: 1280px;
	padding: 20px;
	margin: 0 auto;
}

.hero-text {
	z-index: 100;
	max-width: 700px;
	position: absolute;
	top: 20%;
	margin-right: 20px;
	min-height: 100px;
	background: rgba(0, 0, 0, 0);
	color: #ffffff;
}

.hero-text h1 {
	font-family: "Abel", sans-serif;
	font-size: 48px;
	color: #fff;
}

.hero-text p {
	font-family: "Abel", sans-serif;
	font-size: 22px;
	line-height: 26px;
	color: #ffffff;
}

@media screen and (max-width: 720px) {
	.hero-text {
		max-width: 100%;
	}

	.hero-text h1 {
		font-size: 38px;
		color: #ffffff;
	}

	.hero-text p {
		font-size: 16px;
		line-height: 20px;
		color: #ffffff;
	}

	.logohero {
		max-width: 100%;
		max-height: 100%;
	}
}

@media (min-width: 480px) {
	.hero {
		height: 600px;
	}
}

@media (min-width: 1366px) {
	.hero {
		height: 768px;
	}
}

@media (min-width: 1440px) {
	.hero {
		height: 800px;
	}
}

@media (min-width: 1800px) {
	.hero {
		height: 918px;
	}
}

.hero-button {
	position: absolute;
	width: 300px;
	height: 60px;
	font-size: 1.7rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.hero-button:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.logohero {
	width: auto;
	height: auto;
	left: 150px;
	top: 202px;
}

.year {
	padding-right: 10px;
}

.tipe {
	border: 1px solid #ffffff;
	padding: 5px 5px;
	border-radius: 5px;
}
