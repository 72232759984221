.modal-wrapper {
	position: fixed;
	vertical-align: middle;
	top: 15%;
	left: 38%;
	right: 38%;
	z-index: 100;
	width: auto;
	height: auto;
	overflow: auto;
	margin: auto;
	background-color: #0f0f0f;
	color: white;
	border-radius: 10px;
}

.modal-header {
	background: #263238;
	height: 40px;
	line-height: 40px;
	padding: 5px 20px;
	text-align: right;
}

.modal-header h3 {
	color: white;
	float: left;
	margin: 0;
	padding: 0;
}

.modal-body {
	padding: 10px 15px;
	text-align: center;
}

.modal-footer {
	background: #263238;
	height: 35px;
	padding: 15px;
}

.close-modal-btn {
	color: white;
	cursor: pointer;
	float: right;
	font-size: 30px;
	margin: 0;
}

.close-modal-btn:hover {
	color: black;
}

.btn-cancel,
.btn-continue {
	background: coral;
	border: none;
	color: white;
	cursor: pointer;
	font-weight: bold;
	outline: none;
	padding: 10px;
}

.btn-cancel {
	background-color: #b71c1c;
	float: left;
}

.btn-continue {
	background-color: #1b5e20;
	float: right;
}

.back-drop {
	background-color: rgba(48, 49, 48, 0.42);
	height: 100%;
	position: fixed;
	transition: all 1.3s;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 99;
}

.open-modal-btn {
	margin: 15px;
	padding: 10px;
	font-weight: bold;
}

.close {
	cursor: pointer;
	color: whitesmoke;
	float: right;
	font-size: 15px;
	font-weight: bold;
}

.button {
	position: relative;
	width: 100%;
	height: 50px;
	font-size: 1.2rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.button:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.button-register {
	position: relative;
	width: 100%;
	height: 50px;
	font-size: 1.2rem;
	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: #e50914;
	cursor: pointer;
}

.button-register:hover {
	background: #ffffff;
	border: 1px solid #ffffff;
	color: #7e0006;
}
