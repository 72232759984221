.card {
	border-radius: 5px;
	background-size: 100%, cover !important;
	margin-bottom: .5rem;
	width: 100%;
	height: 50%;
	cursor: pointer;
}

.card:hover {
	transform: translateY(-3px) scale(1);
}

.movie-title {
	font-size: 18px;
	font-weight: 600;
}

.movie-year {
	display: block;
	font-size: 14px;
}
