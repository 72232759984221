@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
.app {
	font-family: 'PT Sans', sans-serif;
}

body {
	background: black;
	margin: 0;
	height: 100%;
}

.form-group {
	margin-bottom: 1rem;
}

.custom-input {
	width: 100%;
	height: 50px;
	background: rgba(210, 210, 210, 0.25);
	border: 2px solid #d2d2d2;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 1rem;
	color: white;
	font-size: 1.2rem;
}

.custom-textarea {
	width: 100%;
	height: 150px;
	background: rgba(210, 210, 210, 0.25);
	border: 2px solid #d2d2d2;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 1rem;
	color: white;
	font-size: 1.2rem;
}

.btn-grey {
	width: 100px;
	height: 30px;
	font-weight: 400;
	font-size: 14px;
	background: rgba(210, 210, 210, 0.25);
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: white;
	cursor: pointer;

	display: inline-block;
}

.btn-merah {
	position: absolute;
	width: 100%;
	height: 40px;
	font-size: 1.2rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.btn-merah:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.custom-select {
	left: 200px;
	font-size: 1.2rem;
	margin-top: 14px;
	height: 50px;
	width: 100%;
	background: rgba(210, 210, 210, 0.25);
	color: rgb(156, 156, 156);
	border: 2px solid white;
	border-radius: 5px;
	padding-left: 0.7rem;
}
.navbar {
	background-color: #1f1f1f;
	overflow: hidden;
	height: 70px;
	z-index: 1000;
	box-shadow: 0 8px 6px -6px black;
	position: fixed;
	top: 0;
	width: 100%;
}

a {
	display: none;
}

.profile {
	display: none;
}

.button-login-register {
	display: none;
}

@media only screen and (min-width: 600px) {
	.navbar a {
		float: left;
		color: #f2f2f2;
		text-align: center;
		padding-top: 27px;
		padding-left: 40px;
		text-decoration: none;
		font-size: 14px;
		color: #ffffff;
		font-weight: 600;
		display: flex;
		align-items: center;
		text-align: center;
		cursor: pointer;
	}

	.navbar a:hover {
		color: #d8d8d8;
	}

	.profile {
		position: absolute;
		right: 27px;
		display: block;
		margin-top: 12px;
	}

	.profile img {
		width: 40px;
		height: 40px;
		border: 2px solid #ffffff;
		border-radius: 50px;
		cursor: pointer;
	}

	.button-login-register {
		position: absolute;
		right: 2%;
		display: block;
		top: 23%;
	}
}

.logo {
	position: absolute;
	left: 50%;
	margin-left: -50px !important;
	display: block;
	top: 17px;
}

.btn-light {
	width: 100px;
	height: 30px;
	font-weight: bold;
	font-size: 14px;
	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: #e50914;
	cursor: pointer;
	margin-top: 4px;
	display: inline-block;
	margin-right: 15px;
}

.btn-light:hover {
	background: #ffffff;
	border: 1px solid #360204;
}

.btn-red {
	width: 100px;
	height: 30px;
	font-weight: bold;
	font-size: 14px;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
	margin-top: 4px;
	display: inline-block;
}

.btn-red:hover {
	background: #e50914;
	border: 1px solid #ffffff;
}

.profile-square {
  display: relative;
  position: fixed;
  right: 28px;
  top: 80px;
  background-color: #1f1f1f;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.profile-square p:hover {
  color: rgb(195, 195, 195);
}

.icon {
  padding-left: 10px;
  color: #e50914;
  display: relative;
}

.submenu {
  color: white;
  display: relative;
}

.submenu:hover {
  color: rgb(195, 195, 195);
}

.profile-dropdown-group {
  display: flex;
  margin-bottom: 18px;
}

.profile-dropdown-icon {
  font-size: 19px;
  width: 15px;
}

.profile-dropdown-link {
  font-size: 19px;
  padding-left: 30px;
}

.profile-arrow {
  position: inherit;
  right: 40px;
  top: 65px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #1f1f1f;
  width: 0;
  height: 0;
}

.modal-wrapper {
	position: fixed;
	vertical-align: middle;
	top: 15%;
	left: 38%;
	right: 38%;
	z-index: 100;
	width: auto;
	height: auto;
	overflow: auto;
	margin: auto;
	background-color: #0f0f0f;
	color: white;
	border-radius: 10px;
}

.modal-header {
	background: #263238;
	height: 40px;
	line-height: 40px;
	padding: 5px 20px;
	text-align: right;
}

.modal-header h3 {
	color: white;
	float: left;
	margin: 0;
	padding: 0;
}

.modal-body {
	padding: 10px 15px;
	text-align: center;
}

.modal-footer {
	background: #263238;
	height: 35px;
	padding: 15px;
}

.close-modal-btn {
	color: white;
	cursor: pointer;
	float: right;
	font-size: 30px;
	margin: 0;
}

.close-modal-btn:hover {
	color: black;
}

.btn-cancel,
.btn-continue {
	background: coral;
	border: none;
	color: white;
	cursor: pointer;
	font-weight: bold;
	outline: none;
	padding: 10px;
}

.btn-cancel {
	background-color: #b71c1c;
	float: left;
}

.btn-continue {
	background-color: #1b5e20;
	float: right;
}

.back-drop {
	background-color: rgba(48, 49, 48, 0.42);
	height: 100%;
	position: fixed;
	transition: all 1.3s;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 99;
}

.open-modal-btn {
	margin: 15px;
	padding: 10px;
	font-weight: bold;
}

.close {
	cursor: pointer;
	color: whitesmoke;
	float: right;
	font-size: 15px;
	font-weight: bold;
}

.button {
	position: relative;
	width: 100%;
	height: 50px;
	font-size: 1.2rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.button:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.button-register {
	position: relative;
	width: 100%;
	height: 50px;
	font-size: 1.2rem;
	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: #e50914;
	cursor: pointer;
}

.button-register:hover {
	background: #ffffff;
	border: 1px solid #ffffff;
	color: #7e0006;
}

.hero {
	background-size: 100%, cover !important;
	background-position: center, center !important;
	width: 100%;
	position: relative;
	background-size: cover;
}

@-webkit-keyframes animateHeroimage {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes animateHeroimage {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.hero-content {
	max-width: 1280px;
	padding: 20px;
	margin: 0 auto;
}

.hero-text {
	z-index: 100;
	max-width: 700px;
	position: absolute;
	top: 20%;
	margin-right: 20px;
	min-height: 100px;
	background: rgba(0, 0, 0, 0);
	color: #ffffff;
}

.hero-text h1 {
	font-family: "Abel", sans-serif;
	font-size: 48px;
	color: #fff;
}

.hero-text p {
	font-family: "Abel", sans-serif;
	font-size: 22px;
	line-height: 26px;
	color: #ffffff;
}

@media screen and (max-width: 720px) {
	.hero-text {
		max-width: 100%;
	}

	.hero-text h1 {
		font-size: 38px;
		color: #ffffff;
	}

	.hero-text p {
		font-size: 16px;
		line-height: 20px;
		color: #ffffff;
	}

	.logohero {
		max-width: 100%;
		max-height: 100%;
	}
}

@media (min-width: 480px) {
	.hero {
		height: 600px;
	}
}

@media (min-width: 1366px) {
	.hero {
		height: 768px;
	}
}

@media (min-width: 1440px) {
	.hero {
		height: 800px;
	}
}

@media (min-width: 1800px) {
	.hero {
		height: 918px;
	}
}

.hero-button {
	position: absolute;
	width: 300px;
	height: 60px;
	font-size: 1.7rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.hero-button:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.logohero {
	width: auto;
	height: auto;
	left: 150px;
	top: 202px;
}

.year {
	padding-right: 10px;
}

.tipe {
	border: 1px solid #ffffff;
	padding: 5px 5px;
	border-radius: 5px;
}

.card {
	border-radius: 5px;
	background-size: 100%, cover !important;
	margin-bottom: .5rem;
	width: 100%;
	height: 50%;
	cursor: pointer;
}

.card:hover {
	transform: translateY(-3px) scale(1);
}

.movie-title {
	font-size: 18px;
	font-weight: 600;
}

.movie-year {
	display: block;
	font-size: 14px;
}

.movie-grid {
	color: white;
	margin: 0;
	margin-left: 8%;
	margin-right: 8%;
	margin-bottom: 2rem;
}

.movie-list {
	display: grid;
	grid-gap: 1.7rem;
	gap: 1.7rem;
	grid-template-columns: repeat(6, 1fr);
}

@media screen and (max-width: 1360px) {
	.movie-list {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 768px) {
	.movie-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 375px) {
	.movie-list {
		grid-template-columns: repeat(1, 1fr);
	}
}

.movie-type {
	font-size: 24px;
	font-weight: bold;
	margin-left: -2%;
	margin-bottom: 20px;
}

.skeleton-container {
    margin-left: 8%;
    margin-right: 8%;
}

.skeleton-description-container {
    margin-left: 8%;
    margin-right: 8%;
    display: flex;
}

.skeleton-container ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.skeleton-hero {
    margin-top: 90px;
    width: 100%;
    height: 800px;
}

.skeleton-description {
    margin-top: 20px;
    display: flex;
    min-height: 45vh;
    width: 60%;
}

.skeleton-episode {
    margin-top: 20px;
    display: flex;
    min-height: 45vh;
    width: 40%;
}


.skeleton-description .desc-right p {
    margin-top: 0px;
    margin-bottom: 10px;
}



.skeleton-video {
    margin-top: 90px;
    width: 100%;
    height: 500px;
}


.skeleton-list {
    display: grid;
    grid-gap: 1.7rem;
    gap: 1.7rem;
    grid-template-columns: repeat(6, 1fr);
}

.skeleton-title {
    margin-top: 30px;
}

.card-link {
    color: inherit;
    text-decoration: none;
}

.card-image {
    width: 100%;
}

.card-title {
    margin-top: 10px;
    margin-bottom: 0;
}

.card-channel {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}


@media screen and (max-width: 1360px) {
    .skeleton-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .skeleton-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 375px) {
    .skeleton-list {
        grid-template-columns: repeat(1, 1fr);
    }
}
.player-wrapper {
  position: relative;
  padding-top: 30%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-image {
  background-size: 100%, cover !important;
  background-position: center, center !important;
  position: relative;
  background-size: cover;
  cursor: pointer;
}

.video-image:hover {
  opacity: 0.9;
}


@media (max-width: 700px) {
  .player-wrapper {
    padding-top: 70%;
  }
}
.description-container {
  font-family: "Muli", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45vh;
  margin: 0;
  width: 60%;
  color: white;
}

.episode-container {
  font-family: "Muli", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45vh;
  margin: 0;
  width: 40%;
  color: white;
}

.movie {
  display: flex;
  max-width: 100%;
  margin: 20px;
  overflow: hidden;
  width: 700px;
}

@media (max-width: 768px) {
  .movie {
    flex-direction: column;
    width: 100%;
  }

  .play-now {
    width: 100%;
  }

  .description-container {
    width: 100%;
  }

  .episode-container {
    width: 100%;
  }
}

.movie-description {
  text-transform: capitalize;
  display: inline-block;
  padding: 0 20px;
}

.year {
  font-weight: bold;
  font-size: 18px;
}

.tipe {
  border: 1px solid #ffffff;
  padding: 5px 5px;
  border-radius: 5px;
}

.play-now {
  flex-direction: column;
  display: flex;
  max-width: 100%;
  margin: 20px;
  overflow: hidden;
  width: 500px;
  position: relative;
}

.description {
  margin-top: 40px;
  font-size: 17px;
}

.img-play-now {
  position: absolute;
  top: 40%;
  align-self: center;
  color: white;
}

.arrow-right {
  position: absolute;
  right: 2%;
  top: 40%;
  font-size: 30px;
  align-self: center;
  color: #e50914;
}

.icon-play {
  position: absolute;
  top: 33%;
  font-size: 70px;
  align-self: center;
  color: #e50914;
  z-index: 1;

}


.icon-play:hover {
  cursor: pointer;
  opacity: 0.8;

}
.details {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10%;
}

@media (max-width: 768px) {
  .details {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2%;
  }
}

.pointer {
  cursor: pointer;
  font-size: 20px;
  text-decoration: underline;
}

.profile-container {
	display: flex;
	margin-top: 130px;
	color: white;
}

.profile-card {
	background-color: #1f1f1f;
	margin: auto;
	padding: 20px;
	border: 1px solid #1f1f1f;
	border-radius: 10px;
	width: 50%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.profile-card {
		flex-direction: column;
	}
}

.profile-desc {
	display: block;
	min-width: 70%;
	max-width: 100%;
	min-height: 50vh;
}

.profile-data {
	display: flex;
	padding-left: 30px;
	margin-bottom: 18px;
}

.profile-details {
	padding-left: 10px;
}

.profile-details span {
	display: block;
}

.profile-icon {
	color: #e50914;
	font-size: 35px;
	width: 45px;
}

.profile-img {
	min-width: 30%;
	max-width: 100%;
	min-height: 50vh;
}

.profile-avatar {
	padding-top: 20px;
	position: relative;
	float: right;
	max-width: 100%;
}

.profile-button {
	position: relative;
	width: 100%;
	height: 60px;
	margin-top: 15px;
	font-size: 1.3rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.profile-button:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.payment-container {
  display: flex;
  margin-top: 130px;
  color: white;
}

.payment-details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 0 30%;
}

.payment-desc {
  text-align: center;
  display: inline-block;
}

.payment-desc p {
  font-size: 20px;
}

.red {
  color: #e50914;
  font-weight: bold;
}

.form-payment {
  padding: 0 25%;
}

.preview-src {
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 758px) {
  .payment-details {
    width: 100%;
    padding: 0 0;
  }
}

.transcation-container {
  display: flex;
  margin-top: 130px;
  color: white;
  flex-direction: column;
  padding-left: 200px;
  padding-right: 200px;
}

@media (max-width: 758px) {
  .transcation-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.transcation-table {
  border-collapse: collapse;
  width: 100%;
}

.transcation-table tr:nth-child(even) {
  background-color: #2b2b2b;
}

.transcation-table tr:nth-child(odd) {
  background-color: #1f1f1f;
}

.transcation-table th,
td {
  border-bottom: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

.transcation-table th {
  color: #e50914;
  font-weight: bold;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1f1f1f;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0 0;
  margin: 0 0;
  z-index: 1;
  color: white;
}

.dropdown-content ul li {
  list-style-type: none;
  cursor: pointer;
}

.dropdown-content ul li:hover {
  opacity: 0.8;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.list-movie {
	margin-top: 100px;
	color: white;
}

.baris-category {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-left: 6%;
	margin-right: 6%;
	margin-bottom: 2%;
	line-height: 100%;
}

.select-category {
	left: 200px;
	position: absolute;
	margin-top: 14px;
	height: 30px;
	width: 150px;
	background: black;
	color: white;
	border: 2px solid white;
	border-radius: 5px;
}

.btn-add-film {
	margin-top: 14px;
	width: 150px;
	height: 30px;
	font-weight: bold;
	font-size: 14px;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
}

.btn-add-film:hover {
	background: #e50914;
	border: 1px solid #ffffff;
}

.container {
	margin: 0 10%;
	position: relative;
}

.satuline {
	display: grid;
	grid-gap: 1.7rem;
	gap: 1.7rem;
	grid-template-columns: repeat(2, 1fr);
}

.preview-container {
	margin-top: 4rem;
	width: 100%;

}

.preview-container ul li {
	color: white;

}

.preview-container h1 {
	color: white;
}

.preview-container .preview-film {
	display: inline-block;
	width: 200px;
}
