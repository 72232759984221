@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

.app {
	font-family: 'PT Sans', sans-serif;
}

body {
	background: black;
	margin: 0;
	height: 100%;
}

.form-group {
	margin-bottom: 1rem;
}

.custom-input {
	width: 100%;
	height: 50px;
	background: rgba(210, 210, 210, 0.25);
	border: 2px solid #d2d2d2;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 1rem;
	color: white;
	font-size: 1.2rem;
}

.custom-textarea {
	width: 100%;
	height: 150px;
	background: rgba(210, 210, 210, 0.25);
	border: 2px solid #d2d2d2;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 1rem;
	color: white;
	font-size: 1.2rem;
}

.btn-grey {
	width: 100px;
	height: 30px;
	font-weight: 400;
	font-size: 14px;
	background: rgba(210, 210, 210, 0.25);
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: white;
	cursor: pointer;

	display: inline-block;
}

.btn-merah {
	position: absolute;
	width: 100%;
	height: 40px;
	font-size: 1.2rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.btn-merah:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}

.custom-select {
	left: 200px;
	font-size: 1.2rem;
	margin-top: 14px;
	height: 50px;
	width: 100%;
	background: rgba(210, 210, 210, 0.25);
	color: rgb(156, 156, 156);
	border: 2px solid white;
	border-radius: 5px;
	padding-left: 0.7rem;
}