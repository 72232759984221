.list-movie {
	margin-top: 100px;
	color: white;
}

.baris-category {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-left: 6%;
	margin-right: 6%;
	margin-bottom: 2%;
	line-height: 100%;
}

.select-category {
	left: 200px;
	position: absolute;
	margin-top: 14px;
	height: 30px;
	width: 150px;
	background: black;
	color: white;
	border: 2px solid white;
	border-radius: 5px;
}

.btn-add-film {
	margin-top: 14px;
	width: 150px;
	height: 30px;
	font-weight: bold;
	font-size: 14px;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
}

.btn-add-film:hover {
	background: #e50914;
	border: 1px solid #ffffff;
}

.container {
	margin: 0 10%;
	position: relative;
}

.satuline {
	display: grid;
	gap: 1.7rem;
	grid-template-columns: repeat(2, 1fr);
}

.preview-container {
	margin-top: 4rem;
	width: 100%;

}

.preview-container ul li {
	color: white;

}

.preview-container h1 {
	color: white;
}

.preview-container .preview-film {
	display: inline-block;
	width: 200px;
}