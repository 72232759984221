.profile-container {
	display: flex;
	margin-top: 130px;
	color: white;
}

.profile-card {
	background-color: #1f1f1f;
	margin: auto;
	padding: 20px;
	border: 1px solid #1f1f1f;
	border-radius: 10px;
	width: 50%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.profile-card {
		flex-direction: column;
	}
}

.profile-desc {
	display: block;
	min-width: 70%;
	max-width: 100%;
	min-height: 50vh;
}

.profile-data {
	display: flex;
	padding-left: 30px;
	margin-bottom: 18px;
}

.profile-details {
	padding-left: 10px;
}

.profile-details span {
	display: block;
}

.profile-icon {
	color: #e50914;
	font-size: 35px;
	width: 45px;
}

.profile-img {
	min-width: 30%;
	max-width: 100%;
	min-height: 50vh;
}

.profile-avatar {
	padding-top: 20px;
	position: relative;
	float: right;
	max-width: 100%;
}

.profile-button {
	position: relative;
	width: 100%;
	height: 60px;
	margin-top: 15px;
	font-size: 1.3rem;
	background: #e50914;
	border: 1px solid #e50914;
	border-radius: 5px;
	color: #ffffff;
	cursor: pointer;
}

.profile-button:hover {
	background: #8b040b;
	border: 1px solid #8b040b;
}
