.movie-grid {
	color: white;
	margin: 0;
	margin-left: 8%;
	margin-right: 8%;
	margin-bottom: 2rem;
}

.movie-list {
	display: grid;
	gap: 1.7rem;
	grid-template-columns: repeat(6, 1fr);
}

@media screen and (max-width: 1360px) {
	.movie-list {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 768px) {
	.movie-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 375px) {
	.movie-list {
		grid-template-columns: repeat(1, 1fr);
	}
}

.movie-type {
	font-size: 24px;
	font-weight: bold;
	margin-left: -2%;
	margin-bottom: 20px;
}
